<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">

                <v-card class="pa-2 pa-md-4">
                    <v-card-text class="text-body-1 text--primary text-hyphenate">
                        <section>
                            <header class="text-h4">
                                Benutzungs&shy;bedingungen Modul Beanstandungsverfahren
                            </header>
                            <p class="text-justify">
                                Das GFS-Serviceportal ermöglicht dem Teilnehmer im Modul „Beanstandungsverfahren“ die Abwicklung von Retaxations-Sachverhalten
                                (Korrektur der Abrechnung des Teilnehmers bei der Arzneimittelkostenerstattung durch den Teilnehmenden KVT) im Rahmen von
                                §{{'\xa0'}}300{{'\xa0'}}SGB{{'\xa0'}}V und den in diesem Zusammenhang bestehenden Rahmen- und Versorgungsverträge. Mit Anwahl
                                dieses Moduls durch den Teilnehmer gilt im Verhältnis zwischen dem Teilnehmer und den die Leistungen dieses Moduls anbietenden
                                Teilnehmenden KVTn Folgendes:
                            </p>
                            <ol class="pt-2">
                                <li>
                                    Der Teilnehmer stimmt zu, dass das Beanstandungsverfahren (einschließlich der Retax-Ankündigungen bzw. Beanstandungen der
                                    Teilnehmenden KVT, der Einsprüche des Teilnehmers und der Mitteilungen von Prüfungsergebnissen zu einem Einspruch durch die
                                    Teilnehmenden KVT) während der Dauer der Teilnahme am Modul Beanstandungsverfahren des GFS-Serviceportals (vgl. zur Dauer im
                                    Einzelnen Ziff. (8) unten) vollständig und ausschließlich über das GFS-Serviceportal abgewickelt wird, soweit es um Abrechnungen
                                    gegenüber den jeweils Teilnehmenden KVTn geht.
                                </li>
                                <li>
                                    Im Rahmen der Abwicklung des Beanstandungsverfahrens über das GFS-Serviceportal nimmt der Teilnehmer zustimmend zur
                                    Kenntnis, dass die Teilnehmenden KVT mit Wirkung gegenüber den teilnehmenden Apotheken auf das Erfordernis der Schriftlichkeit
                                    eines Einspruchs verzichtet haben, soweit der Einspruch über das GFS-Serviceportal in elektronischer Form abgewickelt wird.
                                </li>
                                <li>
                                    Im Rahmen der Abwicklung des Beanstandungsverfahrens über das GFS-Serviceportal verzichtet der Teilnehmer hiermit gegenüber
                                    dem jeweiligen Teilnehmenden KVT – vertreten durch GFS –, soweit die Retax-Regelungen des für das Verhältnis zwischen dem
                                    Teilnehmer und dem jeweili-gen Teilnehmenden KVT maßgeblichen Rahmen- und Versorgungsvertrages die Schriftlichkeit der
                                    Retax-Ankündigungen / Beanstandungen und/oder des Prüfungsergebnisses vorsehen, auf dieses Schriftformerfordernis und wird
                                    dessen Nichteinhaltung nicht gegenüber GFS oder den Teilnehmenden KVTn rügen. Dieser Verzicht ist unwiderruflich für die
                                    Zeit der Teilnahme am GFS-Serviceportal und der Teilnehmer bestätigt ausdrücklich, dass während dieser Zeit der
                                    Informationsaustausch im Rahmen des Beanstandungsverfahrens über das GFS-Serviceportal die Schriftform ersetzt.
                                    Gleichwohl bleibt eine schriftliche Übermittlung von Retax-Ankündigungen / Beanstandungen und/oder von Prüfungsergebnissen
                                    durch GFS bzw. den Teilnehmenden KVTn in Ausnahmefällen zulässig.
                                </li>
                                <li>
                                    Retax-Ankündigungen (Beanstandungen) werden dem Teilnehmer über das GFS-Serviceportal zur Verfügung gestellt. Der Teilnehmer
                                    erhält von GFS eine Benachrichtigungs-Mail und, wenn er daraufhin nicht auf die bereitgestellte Beanstandung zugegriffen hat,
                                    eine Erinnerungs-Mail. Mit Zugang dieser Benachrichtigungs-Mail beginnt der Fristenlauf für Einsprüche des Teilnehmers entspr.
                                    §{{'\xa0'}}13{{'\xa0'}}Abs.{{'\xa0'}}2 Arzneiversorgungsvertrag (Stand 1. März 2021). Die Einspruchsfrist verlängert sich durch
                                    Unterlassen der tatsächlichen Kenntnisnahme durch den Teilnehmer oder durch spätere Benachrichtigungen oder Erinnerungen seitens
                                    GFS nicht.
                                </li>
                                <li>
                                    Der Teilnehmer kann über die Funktionen im GFS-Serviceportal Retax-Sachverhalte (Beanstandungen) einsehen und prüfen sowie
                                    Einsprüche gegenüber dem jeweiligen Teilnehmenden KVT in elektronischer Form erheben. Bei der Erhebung von Einsprüchen erhält der
                                    Teilnehmer von GFS eine Benachrichtigungs-Mail (Eingangsbestätigung). Maßgeblich für die Wahrung der Einspruchsfrist ist der in
                                    der Eingangsbestätigung von GFS dokumentierte Zeitpunkt des Erhalts des Dokuments des Teilnehmers seitens GFS.
                                </li>
                                <li>
                                    Das Ergebnis der Prüfung des Einspruchs wird im GFS-Serviceportal bereitgestellt. Der Teilnehmer erhält von GFS eine
                                    Benachrichtigungs-Mail über die Bereitstellung des Dokuments und, wenn er daraufhin nicht auf das bereitgestellte Dokument
                                    zugegriffen hat, eine Erinnerungs-Mail.
                                </li>
                                <li>
                                    Der Teilnehmer kann einzelne Beanstandungsverfahren an einen Dritten, insbesondere einen Apothekenverband, delegieren,
                                    der insoweit gegenüber dem jeweiligen Teilnehmenden KVT als Erfüllungsgehilfe des Teilnehmers bei der Abwicklung des
                                    Beanstandungsverfahrens tätig wird. Hierzu werden entsprechenden Dritten (auf Wunsch eines Teilnehmers) limitierte
                                    Zugänge zum GFS-Serviceportal zur Verfügung gestellt. Im GFS-Serviceportal kann der Teilnehmer laufende Einspruchsverfahren
                                    markieren und einem vom Teilnehmer aus einer Liste ausgewählten Dritten zuweisen. Dem ausgewählten Dritten werden dann die
                                    maßgeblichen Beanstandungen des jeweiligen KVTs im GFS-Serviceportal in anonymisierter Form zugänglich gemacht. Der Teilnehmer
                                    stellt im Hinblick auf die von ihm an einen Dritten delegierten Beanstandungsverfahren sicher, dass dieser Dritte die Bestimmungen
                                    in Abschnitt I, Ziff. (1) und (2) hinsichtlich seines limitierten Zugangs einhält.
                                </li>
                                <li>
                                    Endet dieses Modul durch Abwahl bzw. (Teil-) Kündigung, so werden vorbehaltlich des folgenden Satzes Beanstandungsverfahren,
                                    die zum Zeitpunkt des Zugangs der Erklärung der Abwahl bzw. (Teil-) Kündigung noch nicht endgültig beendet sind innerhalb von 24h
                                    wirksam auf Papier und somit auf Schriftform umgestellt. Retax-Ankündigungen bzw. Beanstandungen der Teilnehmenden KVT sowie
                                    Mitteilungen der Prüfungsergebnisse zu einem Einspruch durch die Teilnehmenden KVT können ab dem Zugang der Erklärung der Abwahl
                                    bzw. (Teil-) Kündigung nur noch innerhalb von 24h wirksam über das GFS-Serviceportal erklärt werden (d. h. insoweit bedarf es
                                    während dieser Frist weiterhin keiner Schriftform). Nach dem Zugang der Erklärung der Abwahl bzw. (Teil-) Kündigung und nach
                                    Ablauf der 24h Frist, können im GFS-Serviceportal bis zu dieser Frist erklärte Ankündigungen, Beanstandungen und Mitteilungen
                                    der Teilnehmenden KVT zusätzlich auch in Schriftform an den Teilnehmer versendet werden.  Einsprüche des Teilnehmers, die nach
                                    der Er-klärung der Abwahl bzw. (Teil-) Kündigung erhoben werden, bedürfen der Schriftform.
                                </li>
                            </ol>
                        </section>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'Terms',
    }
</script>
<style scoped>
    /*#region Gliederung und Überschriften*/
    section:not(:last-of-type) {
        margin-bottom: 24px;
    }

    section > header {
        margin-bottom: 8px;
        display: flex;
    }
    /*#endregion*/

    /*#region Listen mit einzelnen Punkten*/
    ol {
        counter-reset: paragraph;
        list-style: none;
        padding-left: 2em !important;
    }

    ol > li {
        position: relative;
        text-align: start;
    }

    ol > li:not(:last-of-type) {
        margin-bottom: 8px;
    }

    ol > li::before {
        content: "(" counter(paragraph) ") ";
        counter-increment: paragraph;
        position: absolute;
        left: -2em;
    }
    /*#endregion*/
</style>


